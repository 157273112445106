import * as React from "react";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle";
import "typeface-roboto";
import "typeface-roboto-slab";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import MyHeader from "components/Header/MyHeader";
import MyFooter from "components/Footer/MyFooter";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Button from "components/CustomButtons/Button.js";

// Sections for this page
import SectionProduct from "./HomePage/Sections/SectionProduct";
import SectionContacts from "./HomePage/Sections/SectionContacts";
import SectionTestimonials from "./HomePage/Sections/SectionTestimonials";
import SectionProjects from "./HomePage/Sections/SectionProjects";

const useStyles = makeStyles(landingPageStyle);

// markup
const IndexPage = ({ ...rest }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  return (
    <div>
      <MyHeader {...rest} />
      <Parallax image={require("assets/img/post-production.jpg")} filter="dark">
        <div className={classes.container}>
          <GridContainer alignItems="flex-end" justifyContent="space-between">
            <GridItem xs={12} sm={8} md={8}>
              <h1 className={classes.title} style={{ lineHeight: 1 }}>
                TRACE <br />
                <span style={{ fontSize: "1.5625rem" }}>
                  Element Tracking System (ETS)
                </span>
              </h1>
              <h4>
                TRACE is everything! Literally! All the tasks, all the data, all
                the steps, who did it, when they did it, when you need it, all
                in a gratifying, fun to use, eye pleasing, and easily accessible
                way.
                <p />
                TRACE your elements.
              </h4>
            </GridItem>
            <GridItem xs={4}>
              <Button color="danger" size="lg" href="/">
                <i className="fas fa-play" />
                Book Your Demo
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <SectionProduct />
        </div>
        <SectionTestimonials />
        <SectionProjects />
        <SectionContacts />
      </div>
      <MyFooter {...rest} />
    </div>
  );
};

export default IndexPage;
