/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle";

import broadcast from "assets/img/broadcast.jpg";
import motionPictures from "assets/img/motion_pictures.jpg";
import onDemand from "assets/img/on_demand.jpg";
import videoGames from "assets/img/video_games.jpg";

const useStyles = makeStyles(projectsStyle);

export default function SectionProjects({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.projects + " " + classes.projects3}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>We Provide Solutions for</h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <Card plain className={classes.card2}>
                <CardHeader plain image>
                  <img src={broadcast} alt="..." />
                </CardHeader>
                <CardBody>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <h4 className={classes.cardTitle}>Broadcast</h4>
                  </a>
                  <p className={classes.cardDescription}>
                    Keep track of airdates and never miss a dealine in your
                    promo production pipeline.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              <Card plain className={classes.card2}>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <CardHeader plain image>
                    <img src={motionPictures} alt="..." />
                  </CardHeader>
                </a>
                <CardBody>
                  <h4 className={classes.cardTitle}>Motion Picture</h4>
                  <p className={classes.cardDescription}>
                    Manage the entire process of your trailer production and
                    gain visibility into the status at any moment.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <Card plain className={classes.card2}>
                <CardHeader plain image>
                  <img src={videoGames} alt="..." />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>Video Games</h4>
                  <p className={classes.cardDescription}>
                    Take control of your workflow as Trace and ON-AIR Pro
                    handles the unique needs of video game promotions
                    production.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              <Card plain className={classes.card2}>
                <CardHeader plain image>
                  <img src={onDemand} alt="..." />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>On Demand</h4>
                  <p className={classes.cardDescription}>
                    Keep up with the ever evolving landscape in promo
                    production.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
